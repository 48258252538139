import { Document, Paragraph, TextRun, Packer } from 'docx';
import Docxtemplater from 'docxtemplater';
import React from 'react';
import { unstable_createRoot as createRoot } from 'react-dom';
import FileReader from '@tanker/file-reader';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import clsx from 'clsx';

import {
  MultiSelectIcon,
  FileCodeIcon,
  RocketIcon,
  BroadcastIcon,
  XIcon,
} from '@primer/octicons-react';

const MimeTypes = {
  docx: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ],
  csv: [
    'application/csv',
    'text/csv',
    'text/comma-separated-values',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
};

const Styles = {
  input: {
    display: 'none',
  },
  group: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  container: {
    paddingLeft: 24,
    paddingRight: 24,
  },
};

const parseCSV = (file) => {
  return new Promise((resolve, reject) => {
    return Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      worker: false,
      complete: ({ data }) => resolve(data),
      error: (error) => reject(error),
      skipEmptyLines: true,
    });
  });
};

const UploadButton = React.memo((props) => {
  const {
    children,
    className = '',
    accept = '*',
    icon = null,
    onUpload,
    onClear,
    disabled,
    count,
    ...rest
  } = props;

  const inputRef = React.useRef();

  const handleClick = React.useCallback(() => {
    return inputRef.current.click();
  }, []);

  const handleChange = React.useCallback(
    ({ target }) => onUpload(target.files),
    [onUpload],
  );

  return (
    <>
      <input
        ref={inputRef}
        multiple
        type="file"
        accept={accept}
        style={Styles.input}
        onChange={handleChange}
      />
      <div
        {...rest}
        className={clsx('my-3', className, { BtnGroup: disabled })}
        style={Styles.group}
      >
        <button
          className="btn BtnGroup-item btn-outline"
          type="button"
          disabled={disabled}
          onClick={handleClick}
        >
          {icon}
          <span>
            {children}
            {count ? <span className="Counter">{count}</span> : null}
          </span>
        </button>
        {count ? (
          <button
            className={clsx(
              ['btn', 'BtnGroup-item'],
              disabled ? 'btn-danger' : 'btn-outline',
            )}
            type="button"
            onClick={onClear}
          >
            <XIcon size={16} />
          </button>
        ) : null}
      </div>
    </>
  );
});

const Application = () => {
  const [templates, setTemplates] = React.useState([]);
  const [datas, setDatas] = React.useState([]);

  const fileCodeIcon = React.useMemo(() => <FileCodeIcon size={16} />, []);
  const multiSelectIcon = React.useMemo(
    () => <MultiSelectIcon size={16} />,
    [],
  );

  const handleTemplate = React.useCallback((files) => setTemplates(files), []);
  const handleData = React.useCallback((files) => setDatas(files), []);

  const handleTemplatesClear = React.useCallback(() => setTemplates([]), []);
  const handleDatasClear = React.useCallback(() => setDatas([]), []);

  const handleSample = React.useCallback(async () => {
    const doc = new Document();

    doc.addSection({
      properties: {},
      children: [
        new Paragraph({
          children: [new TextRun('Hello {name}')],
        }),
      ],
    });

    const reader = new FileReader(await Packer.toBlob(doc));
    const docxBuffer = await reader.readAsArrayBuffer();
    const zip = new PizZip();

    zip.file('sample.csv', 'filename,name\njhon-silva,Jhon\ndan-costa,Dan');
    zip.file('sample.docx', docxBuffer, { type: 'uint8array' });

    saveAs(zip.generate({ type: 'blob' }), 'sample.zip');
  }, []);

  const handleProcess = React.useCallback(async () => {
    const mimeType = MimeTypes.docx[0];

    for await (const templateFile of templates) {
      const output = new PizZip();
      for await (const dataFile of datas) {
        for await (const { filename, ...entry } of await parseCSV(dataFile)) {
          const reader = new FileReader(templateFile);
          const templateBuffer = await reader.readAsArrayBuffer();
          const zip = new PizZip(templateBuffer);
          const docx = new Docxtemplater(zip);

          docx.setData(entry);
          docx.render();

          output.file(
            `${filename}.docx`,
            docx.getZip().generate({ type: 'uint8array', mimeType }),
            { type: 'uint8array' },
          );
        }
      }

      saveAs(output.generate({ type: 'blob' }), 'output.zip');
    }

    setTemplates([]);
    setDatas([]);
  }, [datas, templates]);

  return (
    <div
      className="blankslate blankslate-large blankslate-spacious"
      style={Styles.container}
    >
      <BroadcastIcon
        className="octicon octicon-octoface blankslate-icon"
        size={96}
      />
      <h3 className="mb-1">Document generator.</h3>
      <p>
        Process a <code>docx</code> template against a <code>csv</code> data
        source. Get started by downloading a{' '}
        <a
          href="#"
          className="branch-name"
          role="button"
          onClick={handleSample}
        >
          <span>sample</span>
        </a>
        , or go straight into processing mode.
      </p>
      <UploadButton
        className="mr-2"
        accept={MimeTypes.docx.join(', ')}
        icon={fileCodeIcon}
        disabled={Boolean(templates.length)}
        count={templates.length}
        onUpload={handleTemplate}
        onClear={handleTemplatesClear}
      >
        Upload Template
      </UploadButton>
      <UploadButton
        className="ml-2"
        accept={MimeTypes.csv.join(', ')}
        icon={multiSelectIcon}
        disabled={Boolean(datas.length)}
        count={datas.length}
        onUpload={handleData}
        onClear={handleDatasClear}
      >
        Upload Data
      </UploadButton>
      <br />
      <button
        className="btn btn-primary ml-1"
        type="button"
        disabled={!templates.length || !datas.length}
        onClick={handleProcess}
      >
        <RocketIcon size={16} />
        <span>Process</span>
      </button>
    </div>
  );
};

createRoot(document.getElementById('root')).render(<Application />);
